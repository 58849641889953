TabProductDetailPanel
<template>
  <div>
    <div class="title-tabs">
      <b-row>
        <b-col md="6" class="text-left"> General Information </b-col>
        <b-col md="6" class="text-right">
          <span v-if="id !== 0">
            Latest Update
            {{ $moment(form.updated_time).format("DD/MM/YYYY") }} ({{
              $moment(form.updated_time).format("HH:mm:ss")
            }})
          </span>
        </b-col>
      </b-row>
    </div>
    <b-container class="no-gutters bg-white">
      <b-row class="pt-3">
        <b-col>
          <InputText
            textFloat="Product Name"
            placeholder="Product Name"
            type="text"
            name="name"
            isRequired
            v-model="form.name"
            @onDataChange="
              (val) => {
                form.name = val;
              }
            "
            :v="v.form.name"
            :isValidate="v.form.name.$error"
          />
        </b-col>
        <b-col>
          <InputText
            textFloat="Product Price (Baht)"
            placeholder="Product Price (Baht)"
            type="number"
            name="price"
            oninput="if( this.value.length > 7 )  this.value = this.value.slice(0,7)"
            @onKeypress="onKeypressArticleNo"
            isRequired
            v-model="form.price"
            @onDataChange="
              {
                (val) => (form.price = val);
              }
            "
            :v="v.form.price"
            :isValidate="v.form.price.$error"
          />
        </b-col>
      </b-row>
      <div>
        <label>Category <span class="label-error">*</span></label>
        <div :class="['border-cate', v.form.ah_4_id.$error ? 'error' : '']">
          <b-row>
            <b-col class="border-custom" cols="12" sm="12" md="3">
              <div>
                <b-form-input
                  class="search-bar mb-2"
                  placeholder="Search"
                  v-model="searchAH1"
                  @change="handleSearchAH1"
                >
                </b-form-input>
                <img
                  src="@/assets/icons/loading.svg"
                  class="loading"
                  alt="loading"
                  v-if="isLoadingAh1"
                />
                <div v-if="ah1.length > 0" class="row-category">
                  <div v-for="(item1, index1) in ah1" :key="index1">
                    <button
                      :class="['btn-cate', item1.classActive1]"
                      @click.prevent="selectCateAh1(item1, index1)"
                    >
                      <b-row class="cate-name">
                        <b-col class="text-left pl-0">{{
                          item1.ah_1_name
                        }}</b-col>
                        <b-col cols="2" class="text-righ pr-0">
                          <font-awesome-icon icon="chevron-right" />
                        </b-col>
                      </b-row>
                    </button>
                  </div>
                </div>
                <div class="not-found-data" v-else>Not Found</div>
              </div>
            </b-col>
            <b-col class="border-custom" cols="12" sm="12" md="3">
              <b-form-input
                class="search-bar mb-2"
                placeholder="Search"
                v-model="searchAH2"
                @change="handleSearchAH2"
              >
              </b-form-input>
              <img
                src="@/assets/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingAh2"
              />
              <div v-if="ah2.length > 0" class="row-category">
                <div v-for="(item2, index2) in ah2" :key="index2">
                  <button
                    :class="['btn-cate', item2.classActive2]"
                    @click.prevent="selectCateAh2(item2, index2)"
                  >
                    <b-row class="cate-name">
                      <b-col class="text-left pl-0">{{
                        item2.ah_2_name
                      }}</b-col>
                      <b-col cols="2" class="text-right pr-0"
                        ><font-awesome-icon icon="chevron-right"
                      /></b-col>
                    </b-row>
                  </button>
                </div>
              </div>
              <div class="not-found-data" v-else>Not Found</div>
            </b-col>
            <b-col class="border-custom" cols="12" sm="12" md="3"
              ><b-form-input
                class="search-bar mb-2"
                placeholder="Search"
                v-model="searchAH3"
                @change="handleSearchAH3"
              >
              </b-form-input>
              <img
                src="@/assets/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingAh3" />
              <div class="row-category">
                <div v-for="(item3, index3) in ah3" :key="index3">
                  <button
                    :class="['btn-cate', item3.classActive3]"
                    @click.prevent="selectCateAh3(item3, index3)"
                  >
                    <b-row class="cate-name">
                      <b-col class="text-left pl-0">{{
                        item3.ah_3_name
                      }}</b-col>
                      <b-col cols="2" class="text-right pr-0"
                        ><font-awesome-icon icon="chevron-right"
                      /></b-col>
                    </b-row>
                  </button>
                </div></div
            ></b-col>
            <b-col cols="12" sm="12" md="3"
              ><b-form-input
                class="search-bar mb-2"
                placeholder="Search"
                v-model="searchAH4"
                @change="handleSearchAH4"
              >
              </b-form-input>
              <img
                src="@/assets/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoading4"
              />
              <div v-for="(item4, index4) in ah4" :key="index4">
                <button
                  :class="['btn-cate', item4.classActive4]"
                  @click.prevent="selectCateAh4(item4, index4)"
                >
                  <b-row class="cate-name">
                    <b-col class="text-left pl-0">{{ item4.ah_4_name }}</b-col>
                  </b-row>
                </button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="v.form.ah_4_id.$error" class="text-error">
          Please Select Category.
        </div>
      </div>
      <b-row class="mt-3">
        <b-col>
          <InputText
            textFloat="Product Code"
            placeholder="Product Code"
            type="text"
            name="article_no"
            v-model="v.form.article_no.$model"
            @onDataChange="
              (val) => {
                form.article_no = val;
              }
            "
            :v="v.form.article_no"
            :isValidate="v.form.article_no.$error"
            isRequired
          />
        </b-col>
        <b-col>
          <InputText
            textFloat="Barcode"
            placeholder="Barcode"
            type="text"
            name="barcode"
            v-model="form.barcode"
            @onDataChange="
              (val) => {
                form.barcode = val;
              }
            "
            :v="v.form.barcode"
            :isValidate="v.form.barcode.$error"
            isRequired
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <InputSelect
            name="brand"
            title="Manufacturer"
            v-model="form.brand_id"
            v-bind:options="Brands"
            valueField="id"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null"
                >-- Please select value --</b-form-select-option
              >
            </template>
          </InputSelect>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
        <TextEditorsTiny
            textFloat="Product Detail"
            placeholder="Product Detail"
            :value="form.description"
            v-model="form.description"
            @onDataChange="onDataChange"
          />
        </b-col>
      </b-row>

      <b-form-checkbox
        switch
        v-model="form.is_serial"
        class="radio-active my-2"
        size="lg"
        :value="1"
        :unchecked-value="0"
        :disabled="!id"
        @change="handleChangeSerial"
      >
        <span class="ml-2 main-label"> Serial</span>
      </b-form-checkbox>
    </b-container>
    <div class="title-tabs mt-3">Promotion Type</div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3 pb-3">
        <b-row class="mb-3">
          <b-col>
            <b-form-radio v-model="selected" name="product" :value="true">
              <span class="product-radio">Product </span>
            </b-form-radio>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <InputSelect
              textFloat="Choose a product type"
              title="Product Type"
              name="type"
              isRequired
              v-model="form.article_type"
              v-bind:options="productType"
              :v="v.form.article_type"
              :isValidate="v.form.article_type.$error"
              @onDataChange="onDataChangeSelect"
              noPleaseSelect
              valueField="id"
              textField="name"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div class="no-gutters bg-white mt-3 py-2 px-3">
      <b-form-checkbox
        switch
        v-model="form.status"
        class="radio-active"
        size="lg"
        :value="1"
        :unchecked-value="0"
      >
        <span class="ml-2 main-label">{{
          form.status ? "Active" : "Inactive"
        }}</span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";

export default {
  components: {
    InputText,
    TextEditorsTiny,
    InputSelect,
  },
  data() {
    return {
      Brands: [],
      noPleaseSelect: true,
      selected: true,
      id_ah1: 0,
      id_ah2: 0,
      id_ah3: 0,
      id_ah4: 0,
      classActive1: "",
      classActive2: "",
      classActive3: "",
      classActive4: "",
      isLoading4: this.isLoadingAh4,
      searchAH1: "",
      searchAH2: "",
      searchAH3: "",
      searchAH4: "",
    };
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    productType: {
      required: true,
      type: Array,
    },
    ah1: {
      required: true,
      type: Array,
    },
    ah2: {
      required: true,
      type: Array,
    },
    ah3: {
      required: true,
      type: Array,
    },
    ah4: {
      required: true,
      type: Array,
    },
    isLoadingAh1: {
      required: true,
      type: Boolean,
    },
    isLoadingAh2: {
      required: true,
      type: Boolean,
    },
    isLoadingAh3: {
      required: true,
      type: Boolean,
    },
    isLoadingAh4: {
      required: true,
      type: Boolean,
    },
    v: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: Number,
    },
    ah_id: {
      required: false,
    },
  },
  async created() {
    if (this.id !== 0) {
      // // console.log(this.ah4, this.ah1, this.ah2);
      // console.log(this.ah_id);
      this.id_ah1 = this.ah_id.ah1;
      this.id_ah2 = this.ah_id.ah2;
      this.id_ah3 = this.ah_id.ah3;
      this.id_ah4 = this.ah_id.ah4;
      for (const i in this.ah1) {
        if (this.ah1[i].ah_1_id === this.form.ah_1_id) {
          this.ah1[i].classActive1 = "active";
          this.ah1[i].select_order = 0;
        } else this.ah1[i].select_order = 1;
      }
      for (const j in this.ah2) {
        if (this.ah2[j].ah_2_id === this.form.ah_2_id) {
          this.ah2[j].classActive2 = "active";
          this.ah2[j].select_order = 0;
        } else this.ah2[j].select_order = 1;
      }
      for (const k in this.ah3) {
        if (this.ah3[k].ah_3_id === this.form.ah_3_id) {
          this.ah3[k].classActive3 = "active";
          this.ah3[k].select_order = 0;
        } else this.ah3[k].select_order = 1;
      }
      for (const x in this.ah4) {
        if (this.ah4[x].ah_4_id === this.form.ah_4_id) {
          this.ah4[x].classActive4 = "active";
          this.ah4[x].select_order = 0;
        } else this.ah4[x].select_order = 1;
      }
      this.ah1 = this.ah1.sort((a, b) => a.select_order - b.select_order);
      this.ah2 = this.ah2.sort((a, b) => a.select_order - b.select_order);
      this.ah3 = this.ah3.sort((a, b) => a.select_order - b.select_order);
      this.ah4 = this.ah4.sort((a, b) => a.select_order - b.select_order);
    }

    const res = await this.axios(`/Brand/GetListBrandOption`);
    this.Brands = res.data.detail;
  },
  methods: {
    selectCateAh1(item, index) {
      for (const i in this.ah1) {
        if (this.ah1[i].classActive1 === "active") {
          this.ah1[i].classActive1 = "";
        }
      }
      this.id_ah1 = item.ah_1_id;
      this.ah1[index].classActive1 = "active";
      this.$emit("searchAH2", item.ah_1_id);
    },
    selectCateAh2(item, index) {
      for (const i in this.ah2) {
        if (this.ah2[i].classActive2 === "active") {
          this.ah2[i].classActive2 = "";
        }
      }
      this.id_ah2 = item.ah_2_id;
      this.ah2[index].classActive2 = "active";
      this.$emit("searchAH3", { ah1: this.id_ah1, ah2: item.ah_2_id });
    },
    selectCateAh3(item, index) {
      for (const i in this.ah3) {
        if (this.ah3[i].classActive3 === "active") {
          this.ah3[i].classActive3 = "";
        }
      }
      this.id_ah3 = item.ah_3_id;
      this.ah3[index].classActive3 = "active";
      console.log(item, index, this.id_ah1);
      this.$emit("searchAH4", {
        ah1: this.id_ah1,
        ah2: this.id_ah2,
        ah3: item.ah_3_id,
      });
    },
    selectCateAh4(item, index) {
      console.log(item, index);
      for (const i in this.ah4) {
        if (this.ah4[i].classActive4 === "active") {
          this.ah4[i].classActive4 = "";
        }
      }
      this.id_ah4 = item.ah_4_id;
      this.form.ah_4_id = item.ah_4_id;
      this.isLoading4 = true;
      this.ah4[index].classActive4 = "active";
      this.$emit("getAH4", this.id_ah4);
      this.isLoading4 = false;
    },
    onDataChangeSelect(val) {
      this.form.article_type = val;
    },
    handleSearchAH1(val) {
      this.$emit("searchAH1ByKeyword", val);
    },
    handleSearchAH2(val) {
      this.$emit("searchAH2ByKeyword", val);
    },
    handleSearchAH3(val) {
      this.$emit("searchAH3ByKeyword", val);
    },
    handleSearchAH4(val) {
      this.$emit("searchAH4ByKeyword", val);
    },
    onDataChange(val) {
      this.form.description = val;
    },
    onKeypressArticleNo(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleChangeSerial() {
      this.$emit("handleSerial", this.form.is_serial);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-custom {
  background-color: var(--theme-secondary-color);
  color: #000;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
}
.border-cate {
  border: 1px solid #d8dbe0;
  padding: 15px 15px;
  border-radius: 5px;
}
.border-custom {
  border-right: 1px solid #d8dbe0;
}

.label-error {
  color: red;
}
.product-radio {
  color: var(--font-main-color);
  font-size: 14px;
  font-weight: 100;
}
.cate-name {
  color: var(font-primary-color);
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}
.update-least {
  color: #767676;
  font-weight: 100;
}
.btn-cate {
  width: 100%;
  background-color: transparent;
  border: none;
}
.btn-cate:hover {
  background-color: #ededed;
  border-radius: 0.25rem;
  width: 100%;
}
.active {
  background-color: var(--theme-secondary-color);
  border-radius: 0.25rem;
}

.text-error {
  color: red;
}
.not-found-data {
  color: gray;
  text-align: center;
  padding: 50px 10px;
}
.border-custom::-webkit-scrollbar {
  display: none;
}
.row-category {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0.25rem;
}
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
</style>
